import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createInvoiceAction = createAsyncThunk(
  "invoice/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("sliceinvocie", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      if (!Array.isArray(info.details)) {
        throw new Error("Details must be an array");
      }

      // Validate each detail object to ensure it matches the schema
      info.details.forEach((detail) => {
        if (!detail.details || !detail.amount) {
          throw new Error(
            'Each detail must have "details" and "amount" fields'
          );
        }
        // Ensure amount is a number
        detail.amount = parseFloat(detail.amount);
        // Ensure qty and discount are numbers with default values
        detail.qty = parseFloat(detail.qty) || 1;
        detail.discount = parseFloat(detail.discount) || 0;
      });
      const { data } = await axios.post(
        `${baseURL}/api/invoice`,
        {
          code: info?.code,
          amount_non_pkr: info.amount_non_pkr,
          conversion_rate: info.conversion_rate,
          pkr: info?.pkr,
          amount: info?.amount,
          paid: info?.paid,
          contractor: info?.contractor,
          subcode: info?.subcode,
          invoice: info?.invoice,
          description: info?.description,
          cheque_number: info?.cheque_number,
          bank_name: info?.bank_name,
          transaction_id: info?.transaction_id,
          unit_price: info?.unit_price,
          advance_payment: info?.advance_payment,
          payment: info?.payment,
          project: info?.project,
          date: info?.date,
          qty: info?.qty,
          units: info?.unit,
          type: info?.type,
          discount: info?.discount,
          details: info?.details,
          concerned_person: info?.concerned_person,
          extra_location: info?.extra_location,
          email: info?.email,
          phone: info?.phone,
          billing_duration: info?.billing_duration,
          tax_plus_minus: info?.tax_plus_minus,
          tax_percentage: info?.tax_percentage,
          tax_show: info?.tax_show,
        },
        config
      );
      console.log("DATA", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchInvoicesAction = createAsyncThunk(
  "invoice/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("brand", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/invoice`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getInvoiceAction = createAsyncThunk(
  "invoice/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("id", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/api/invoice/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateInvoiceAction = createAsyncThunk(
  "invoice/update",

  async (info, { rejectWithValue, getState, dispatch }) => {
    console.log("invoice", info);
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("uidser", info.id);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/invoice/${info.id}`,
        {
          code: info?.code,
          amount_non_pkr: info.amount_non_pkr,
          conversion_rate: info.conversion_rate,
          pkr: info?.pkr,
          amount: info?.amount,
          paid: info?.paid,
          cheque_number: info?.cheque_number,
          bank_name: info?.bank_name,
          transaction_id: info?.transaction_id,
          description: info?.description,
          contractor: info?.contractor,
          subcode: info?.subcode,
          invoice: info?.invoice,
          payment: info?.payment,
          project: info?.project,
          advance_payment: info?.advance_payment,
          date: info?.date,
          qty: info?.qty,
          unit_price: info?.unit_price,
          discount: info?.discount,
          details: info?.details,
          concerned_person: info?.concerned_person,
          extra_location: info?.extra_location,
          email: info?.email,
          phone: info?.phone,
          billing_duration: info?.billing_duration,
          tax_plus_minus: info?.tax_plus_minus,
          tax_percentage: info?.tax_percentage,
          tax_show: info?.tax_show,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteInvoiceAction = createAsyncThunk(
  "invoice/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const info = getState();
    console.log("xxll", info);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(
        `${baseURL}/api/invoice/${id}`,
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const invoiceSlices = createSlice({
  name: "invoice",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createInvoiceAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createInvoiceAction.fulfilled, (state, action) => {
      state.loading = false;
      state.invoice = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createInvoiceAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchInvoicesAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchInvoicesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.invoices = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchInvoicesAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    //Get   By Id
    builder.addCase(getInvoiceAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getInvoiceAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getInvoice = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getInvoiceAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update   By Id
    builder.addCase(updateInvoiceAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateInvoiceAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateInvoice = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateInvoiceAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete
    builder.addCase(deleteInvoiceAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteInvoiceAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteInvoice = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteInvoiceAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default invoiceSlices.reducer;
