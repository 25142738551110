import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createTypesAction = createAsyncThunk(
  "type/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("code", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/type`,
        {
          name: info?.name,
        },
        config
      );
      console.log("DATA", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchTypesAction = createAsyncThunk(
  "type/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("brand", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/type`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getTypeAction = createAsyncThunk(
  "type/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("id", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/api/type/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateTypeAction = createAsyncThunk(
  "type/update",

  async (info, { rejectWithValue, getState, dispatch }) => {
    console.log("updateBrand", info);
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("uidser", info.id);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/type/${info.id}`,
        {
          name: info?.name,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteTypeAction = createAsyncThunk(
  "type/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const info = getState();
    console.log("xxll", info);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(`${baseURL}/api/type/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const typeSlices = createSlice({
  name: "type",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createTypesAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createTypesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.type = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createTypesAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchTypesAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTypesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.type = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchTypesAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    //Get   By Id
    builder.addCase(getTypeAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getTypeAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getType = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getTypeAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update   By Id
    builder.addCase(updateTypeAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateTypeAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateType = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateTypeAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete
    builder.addCase(deleteTypeAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteTypeAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteType = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteTypeAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default typeSlices.reducer;
