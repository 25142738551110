import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createCreditAction = createAsyncThunk(
  "credit/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("code", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/credit`,
        {
          code: info?.code,
          amount: info?.amount,
          contractor: info?.contractor,
          subcode: info?.subcode,
          invoice: info?.invoice,
          description: info?.description,
          cheque_number: info?.cheque_number,
          bank_name: info?.bank_name,
          transaction_id: info?.transaction_id,
          unit_price: info?.unit_price,

          payment: info?.payment,
          project: info?.project,
          date: info?.date,
          qty: info?.qty,
          units: info?.unit,
          type: info?.type,
          discount: info?.discount,
        },
        config
      );
      console.log("DATA", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchCreditsAction = createAsyncThunk(
  "credit/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("brand", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/credit`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCreditAction = createAsyncThunk(
  "credit/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("id", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/api/credit/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCreditAction = createAsyncThunk(
  "credit/update",

  async (info, { rejectWithValue, getState, dispatch }) => {
    console.log("credit", info);
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("uidser", info.id);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/credit/${info.id}`,
        {
          code: info?.code,
          amount: info?.amount,
          cheque_number: info?.cheque_number,
          bank_name: info?.bank_name,
          transaction_id: info?.transaction_id,
          description: info?.description,
          contractor: info?.contractor,
          subcode: info?.subcode,
          invoice: info?.invoice,
          payment: info?.payment,
          project: info?.project,
          date: info?.date,
          qty: info?.qty,
          units: info?.unit,
          type: info?.type,
          unit_price: info?.unit_price,
          discount: info?.discount,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteCreditAction = createAsyncThunk(
  "credit/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const info = getState();
    console.log("xxll", info);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(
        `${baseURL}/api/credit/${id}`,
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const creditSlices = createSlice({
  name: "credit",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createCreditAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createCreditAction.fulfilled, (state, action) => {
      state.loading = false;
      state.credit = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createCreditAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchCreditsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCreditsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.credits = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchCreditsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    //Get   By Id
    builder.addCase(getCreditAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getCreditAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getCredit = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getCreditAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update   By Id
    builder.addCase(updateCreditAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateCreditAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateCredit = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateCreditAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete
    builder.addCase(deleteCreditAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteCreditAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteCredit = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteCreditAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default creditSlices.reducer;
