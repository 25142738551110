import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createPropertyAction = createAsyncThunk(
  "property/create",
  async (property, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("event", property);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/property`,
        {
          title: property?.title,
        },
        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchPropertiesAction = createAsyncThunk(
  "property/fetch",
  async (property, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("event", property);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/property`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const propertySlices = createSlice({
  name: "property",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createPropertyAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createPropertyAction.fulfilled, (state, action) => {
      state.loading = false;
      state.property = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createPropertyAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchPropertiesAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchPropertiesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.properties = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchPropertiesAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default propertySlices.reducer;
