import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createDebitAction = createAsyncThunk(
  "debit/create",
  async (debit, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("debit", debit);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/debit`,
        {
          code: debit?.code,
          amount: debit?.amount,
          contractor: debit?.contractor,
          subcode: debit?.subcode,
          invoice: debit?.invoice,
          description: debit?.description,
          cheque_number: debit?.cheque_number,
          bank_name: debit?.bank_name,
          transaction_id: debit?.transaction_id,
          unit_price: debit?.unit_price,
          discount: debit?.discount,

          payment: debit?.payment,
          project: debit?.project,
          date: debit?.date,
          qty: debit?.qty,
          units: debit?.unit,
          type: debit?.type,
        },
        config
      );
      console.log("DATA", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchDebitsAction = createAsyncThunk(
  "debit/fetch",
  async (debit, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(`${baseURL}/api/debit`, config);
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getDebitAction = createAsyncThunk(
  "debit/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("sliceid", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/api/debit/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateDebitAction = createAsyncThunk(
  "debit/update",

  async (debit, { rejectWithValue, getState, dispatch }) => {
    console.log("debit", debit);
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("uidser", debit.id);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/debit/${debit.id}`,
        {
          code: debit?.code,
          amount: debit?.amount,
          cheque_number: debit?.cheque_number,
          bank_name: debit?.bank_name,
          transaction_id: debit?.transaction_id,
          description: debit?.description,
          contractor: debit?.contractor,
          subcode: debit?.subcode,
          invoice: debit?.invoice,
          payment: debit?.payment,
          project: debit?.project,
          date: debit?.date,
          qty: debit?.qty,
          units: debit?.unit,
          discount: debit?.discount,

          type: debit?.type,
          unit_price: debit?.unit_price,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteDebitAction = createAsyncThunk(
  "debit/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const debit = getState();
    console.log("xxll", debit);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(`${baseURL}/api/debit/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const debitSlices = createSlice({
  name: "debit",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createDebitAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createDebitAction.fulfilled, (state, action) => {
      state.loading = false;
      state.debit = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createDebitAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchDebitsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchDebitsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.debits = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchDebitsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    //Get User By Id
    builder.addCase(getDebitAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getDebitAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getDebit = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getDebitAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update User By Id
    builder.addCase(updateDebitAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateDebitAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateDebit = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateDebitAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete
    builder.addCase(deleteDebitAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteDebitAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteDebit = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteDebitAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default debitSlices.reducer;
