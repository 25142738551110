import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createUnitsAction = createAsyncThunk(
  "units/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("code", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/unit`,
        {
          name: info?.name,
        },
        config
      );
      console.log("DATA", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchUnitsAction = createAsyncThunk(
  "units/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("brand", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/unit`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUnitAction = createAsyncThunk(
  "units/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("id", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/api/unit/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateUnitAction = createAsyncThunk(
  "units/update",

  async (info, { rejectWithValue, getState, dispatch }) => {
    console.log("updateBrand", info);
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("uidser", info.id);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/unit/${info.id}`,
        {
          name: info?.name,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteUnitAction = createAsyncThunk(
  "units/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const info = getState();
    console.log("xxll", info);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(`${baseURL}/api/unit/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const unitSlices = createSlice({
  name: "units",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createUnitsAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createUnitsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.unit = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createUnitsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchUnitsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUnitsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.units = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchUnitsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    //Get   By Id
    builder.addCase(getUnitAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getUnitAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getUnit = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getUnitAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update   By Id
    builder.addCase(updateUnitAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateUnitAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateUnit = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateUnitAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete
    builder.addCase(deleteUnitAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteUnitAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteType = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteUnitAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default unitSlices.reducer;
