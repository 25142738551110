import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logoutUserAction } from "../users/userSlices";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createAttendanceAction = createAsyncThunk(
  "attendance/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("band", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/attendance`,
        {
          totalTime: info?.totalTime,
          activeTime: info?.activeTime,
          totalBreakTime: info?.totalBreakTime,
          inTimePKT: info?.inTimePKT,
          breakTimePKT: info?.breakTimePKT,
          endTimePKT: info?.endTimePKT,
          outTimePKT: info?.outTimePKT,
        },
        config
      );

      console.log("asd", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAttendancesAction = createAsyncThunk(
  "attendance/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      const user = getState()?.users;
      const { userAuth } = user;

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(`${baseURL}/api/attendance`, config);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      } else if (error.response.status === 401) {
        // If the error is a 401 unauthorized, dispatch the logout action
        dispatch(logoutUserAction());
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const bandSlices = createSlice({
  name: "attendance",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createAttendanceAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createAttendanceAction.fulfilled, (state, action) => {
      state.loading = false;
      state.attendance = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createAttendanceAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchAttendancesAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAttendancesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.attendances = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchAttendancesAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
  },
});

export default bandSlices.reducer;
