import React, { useContext, useState } from 'react';
import { WindmillContext } from '@windmill/react-ui';

export default function Pagination({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const { mode, toggleMode } = useContext(WindmillContext);

  return (
    <div className='py-2 my-8 w-full max-w-6xl mx-auto'>
      {mode === 'dark' ? (
        <div>
          <p className='text-sm text-white'>
            Showing &nbsp;
            <span className='font-medium'>Page: {currentPage}</span> for
            <span className='font-medium'> {totalPosts} </span>
            results
          </p>
        </div>
      ) : (
        <div>
          <p className='text-sm text-gray-700'>
            Showing &nbsp;
            <span className='font-medium'>Page: {currentPage}</span> for
            <span className='font-medium'> {totalPosts} </span>
            results
          </p>
        </div>
      )}
      <nav className='block bg-cpurple text-yellow'>
        <ul className='flex pl-0 rounded list-none flex-wrap'>
          <li>
            {pageNumbers.map((number, index) => (
              <a
                key={index}
                onClick={() => {
                  paginate(number);
                }}
                href='#'
                className={
                  currentPage === number
                    ? ' bg-cyellow border-red-300 text-cgreen hover:text-white hover:bg-cgreen-400 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                }
              >
                {number}
              </a>
            ))}
          </li>
        </ul>
      </nav>
    </div>
  );
}
