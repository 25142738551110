import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createContractorAction = createAsyncThunk(
  "contractor/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("code", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/contractor`,
        {
          name: info?.name,
          phone: info?.phone,
          address: info?.address,
        },
        config
      );
      console.log("DATA", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchContractorsAction = createAsyncThunk(
  "contractor/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("brand", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/contractor`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getContractorAction = createAsyncThunk(
  "contractor/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("id", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(
        `${baseURL}/api/contractor/${id}`,
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateContractorAction = createAsyncThunk(
  "contractor/update",

  async (info, { rejectWithValue, getState, dispatch }) => {
    console.log("contractor", info);
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("uidser", info.id);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/contractor/${info.id}`,
        {
          name: info?.name,
          address: info?.address,
          phone: info?.phone,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteContractorAction = createAsyncThunk(
  "contractor/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const info = getState();
    console.log("xxll", info);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(
        `${baseURL}/api/contractor/${id}`,
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const contractorSlices = createSlice({
  name: "contractor",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createContractorAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createContractorAction.fulfilled, (state, action) => {
      state.loading = false;
      state.contractor = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createContractorAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchContractorsAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchContractorsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.contractors = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchContractorsAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    //Get   By Id
    builder.addCase(getContractorAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getContractorAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getContractor = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getContractorAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update   By Id
    builder.addCase(updateContractorAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateContractorAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateContractor = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateContractorAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete
    builder.addCase(deleteContractorAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteContractorAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteContractor = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteContractorAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default contractorSlices.reducer;
