import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/users/userSlices";

import codeSlices from "../slices/codes/codeSlices";
import projectSlices from "../slices/projects/projectSlices";
import contractorSlices from "../slices/contractors/contractorSlices";
import debitSlices from "../slices/debits/debitSlices";
import expenseSlices from "../slices/expenses/expenseSlices";
import creditSlices from "../slices/credits/creditSlices";
import typeSlices from "../slices/types/typeSlices";
import unitSlices from "../slices/units/unitSlices";
import subcodeSlices from "../slices/subcodes/subcodeSlices";
import attendanceSlices from "../slices/attendance/attendanceSlices";
import invoiceSlices from "../slices/invoices/invoiceSlices";

const store = configureStore({
  reducer: {
    users: userReducer,

    codes: codeSlices,
    projects: projectSlices,
    contractors: contractorSlices,
    debits: debitSlices,
    expenses: expenseSlices,
    credits: creditSlices,
    types: typeSlices,
    units: unitSlices,
    subcodes: subcodeSlices,
    attendances: attendanceSlices,
    invoices: invoiceSlices,
  },
});

export default store;
