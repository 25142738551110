import React, { useState, useEffect, useContext } from "react";
import { WindmillContext } from "@windmill/react-ui";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "@windmill/react-ui";
import PageTitle from "../components/Typography/PageTitle";
import SectionTitle from "../components/Typography/SectionTitle";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
} from "@windmill/react-ui";
import { EditIcon, TrashIcon } from "../icons";
import { useDispatch, useSelector } from "react-redux"; //Form schema
import { useFormik } from "formik";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
import {
  createPropertyAction,
  fetchPropertiesAction,
} from "../redux/slices/properties/propertySlices";
import Pagination from "../components/Pagination";
// make a copy of the data, for the second table

const formSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

export default function Properties() {
  const { mode, toggleMode } = useContext(WindmillContext);

  /**
   * DISCLAIMER: This code could be badly improved, but for the sake of the example
   * and readability, all the logic for both table are here.
   * You would be better served by dividing each table in its own
   * component, like Table(?) and TableWithActions(?) hiding the
   * presentation details away from the page view.
   */

  // setup pages control for every table
  const [pageTable1, setPageTable1] = useState(1);
  const [pageTable2, setPageTable2] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  function openEditModal() {
    setIsEditModalOpen(true);
  }

  function closeEditModal() {
    setIsEditModalOpen(false);
  }

  const dispatch = useDispatch();
  //get data from store
  const state = useSelector((state) => state?.properties);
  const { loading, appErr, serverErr, properties } = state;
  console.log(properties);

  //formik
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: (values) => {
      console.log(values);
      dispatch(createPropertyAction(values));
      setIsModalOpen(false);
    },
    validationSchema: formSchema,
  });

  useEffect(() => {
    dispatch(fetchPropertiesAction());
  }, [dispatch]);

  //Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentItems = properties?.slice(indexOfFirstPost, indexOfLastPost);
  console.log(currentItems);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <PageTitle>Properties</PageTitle>

      <div className='flex h-16 items-center justify-between px-4 mb-8 text-sm font-semibold text-purple-100 bg-cdark rounded-lg shadow-md focus:outline-none focus:shadow-outline-purple'>
        <p className='text-white text-lg'>Add Property</p>
        <div className='flex items-center'>
          <div>
            <button
              className='bg-black p-2 rounded-md hover:bg-clight w-32'
              onClick={openModal}
            >
              Add
            </button>
          </div>

          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <ModalHeader>Add Property</ModalHeader>
            <ModalBody>
              <form className='mt-8 space-y-6 ' onSubmit={formik.handleSubmit}>
                <input type='hidden' name='remember' defaultValue='true' />
                <div className='rounded-md shadow-sm -space-y-px'>
                  <div>
                    <label htmlFor='email-address' className='sr-only'>
                      Name
                    </label>
                    {/* Title */}
                    <input
                      value={formik.values.title}
                      onChange={formik.handleChange("title")}
                      onBlur={formik.handleBlur("title")}
                      type='text'
                      autoComplete='text'
                      className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-center focus:z-10 sm:text-sm'
                      placeholder='New Property'
                    />
                    <div className='text-red-400 mb-2'>
                      {formik.touched.title && formik.errors.title}
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    {/* Submit */}
                    <button
                      type='submit'
                      className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cdark hover:bg-clight  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      Add new Property
                    </button>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <SectionTitle>Manage Your Properties</SectionTitle>
      <TableContainer className='mb-8'>
        <Table>
          <TableHeader>
            <tr>
              <TableCell>Name</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Created At</TableCell>

              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {currentItems?.map((item, i) => (
              <TableRow key={i}>
                <TableCell>
                  <div className='flex items-center text-sm'>
                    <div>
                      <p className='font-semibold'>{item?.title}</p>
                    </div>
                  </div>
                </TableCell>

                <TableCell>
                  <span className='text-sm'>
                    {item?.user?.firstName} {item?.user?.lastName}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='text-sm'>
                    {new Date(item?.createdAt).toLocaleDateString()}
                  </span>
                </TableCell>
                <TableCell>
                  <div className='flex items-center space-x-4'>
                    <Button
                      layout='link'
                      size='icon'
                      aria-label='Edit'
                      onClick={openEditModal}
                    >
                      <EditIcon className='w-5 h-5' aria-hidden='true' />
                    </Button>
                    <Button layout='link' size='icon' aria-label='Delete'>
                      <TrashIcon className='w-5 h-5' aria-hidden='true' />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={properties?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </TableFooter>
      </TableContainer>

      <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
        <ModalHeader>Edit Property</ModalHeader>
        <ModalBody>
          <form className='mt-8 space-y-6 ' onSubmit={formik.handleSubmit}>
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='rounded-md shadow-sm -space-y-px'>
              <div>
                <label htmlFor='email-address' className='sr-only'>
                  Name
                </label>
                {/* Title */}
                <input
                  value={formik.values.title}
                  onChange={formik.handleChange("title")}
                  onBlur={formik.handleBlur("title")}
                  type='text'
                  autoComplete='text'
                  className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-center focus:z-10 sm:text-sm'
                  placeholder='New Property'
                />
                <div className='text-red-400 mb-2'>
                  {formik.touched.title && formik.errors.title}
                </div>
              </div>
            </div>

            <div>
              <div>
                {/* Submit */}
                <button
                  type='submit'
                  className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cdark hover:bg-clight  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  Add new Property
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

function AddProperty() {
  return <></>;
}
