import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

export const createExpenseAction = createAsyncThunk(
  "expense/create",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("code", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.post(
        `${baseURL}/api/expense`,
        {
          date: info?.date,
          description: info?.description,
          payment: info?.payment,
          amount: info?.amount,
          code: info?.code,
          subcode: info?.subcode,
        },
        config
      );
      console.log("DATA", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchExpensesAction = createAsyncThunk(
  "expense/fetch",
  async (info, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("brand", info);
      const user = getState()?.users;
      const { userAuth } = user;
      console.log(userAuth);

      const config = {
        headers: {
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.get(
        `${baseURL}/api/expense`,

        config
      );
      console.log(data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getExpenseAction = createAsyncThunk(
  "expense/get",

  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log("id", id);
      const user = getState()?.users;
      const { userAuth } = user;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };

      const { data } = await axios.get(`${baseURL}/api/expense/${id}`, config);
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateExpenseAction = createAsyncThunk(
  "expense/update",

  async (info, { rejectWithValue, getState, dispatch }) => {
    console.log("contractor", info);
    try {
      const user = getState()?.users;
      const { userAuth } = user;
      console.log("uidser", info.id);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.put(
        `${baseURL}/api/expense/${info.id}`,
        {
          date: info?.date,
          description: info?.description,
          payment: info?.payment,
          amount: info?.amount,
          code: info?.code,
          subcode: info?.subcode,
        },
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteExpenseAction = createAsyncThunk(
  "expense/delete",

  async (id, { rejectWithValue, getState, dispatch }) => {
    const info = getState();
    console.log("xxll", info);
    const user = getState()?.users;
    const { userAuth } = user;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${userAuth?.token}`,
        },
      };
      const { data } = await axios.delete(
        `${baseURL}/api/expense/${id}`,
        config
      );
      console.log("res", data);
      return data;
    } catch (error) {
      if (!error?.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

const expenseSlices = createSlice({
  name: "expense",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createExpenseAction.pending, (state, action) => {
      state.loading = true;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createExpenseAction.fulfilled, (state, action) => {
      state.loading = false;
      state.expense = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(createExpenseAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });
    builder.addCase(fetchExpensesAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchExpensesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.expenses = action?.payload;
      state.appErr = undefined;
      state.serverErr = undefined;
    });
    builder.addCase(fetchExpensesAction.rejected, (state, action) => {
      state.loading = false;
      state.appErr = action?.payload.message;
      state.serverErr = action?.error?.message;
    });

    //Get   By Id
    builder.addCase(getExpenseAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getExpenseAction.fulfilled, (state, action) => {
      state.loading = false;
      state.getExpense = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(getExpenseAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Update   By Id
    builder.addCase(updateExpenseAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateExpenseAction.fulfilled, (state, action) => {
      state.loading = false;
      state.updateExpense = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(updateExpenseAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });

    //Delete
    builder.addCase(deleteExpenseAction.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteExpenseAction.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteExpense = action?.payload;

      state.appError = undefined;
      state.serverError = undefined;
    });

    builder.addCase(deleteExpenseAction.rejected, (state, action) => {
      state.loading = false;
      state.appError = action?.payload?.message;
      state.serverError = action?.error?.message;
    });
  },
});

export default expenseSlices.reducer;
