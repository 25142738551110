import React, { lazy } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { Provider as RProvider } from "react-redux";
import store from "./redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import Properties from "./pages/Properties";
import { Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
// optional cofiguration
const options = {
  position: "top center",
  timeout: 2000,
  offset: "100px",
  transition: "scale",
};
function App() {
  return (
    <>
      <Provider template={AlertTemplate} {...options}>
        <RProvider store={store}>
          <Router>
            <AccessibleNavigationAnnouncer />
            <Switch>
              <Route path='/login' component={Login} />
              <Route path='/register' component={Register} />
              <Route path='/forgot-password' component={ForgotPassword} />

              {/* Place new routes over this */}
              <Route path='/app' component={Layout} />
              {/* If you have an index page, you can remothis Redirect */}
              <Redirect exact from='/' to='/login' />
            </Switch>
          </Router>
        </RProvider>{" "}
      </Provider>
    </>
  );
}

export default App;
