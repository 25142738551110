export default {
  button: {
    primary: {
      base: "text-white bg-purple-600 border border-transparent",
      active:
        "active:bg-purple-600 hover:bg-purple-700 focus:ring focus:ring-green-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline: {
      base: "text-black  ",

      disabled: "opacity-50 cursor-not-allowed",
    },
  },
};
